import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { InputBase, makeStyles } from "@material-ui/core";
import Flex from "components/flex/Flex";
import Button from "components/button/Button";
import GridRow from "components/grid-row/GridRow";
import FindAddress from "components/find-address/FindAddress";
import AdminConfirmButton from "components/admin-confirm-button/AdminConfirmButton";
import * as APIS from "libs/apis";

import { validation } from "libs/utils";
import { closeModal, openModal } from "redux/modal/ModalReducer";
import { actionError, actionOpen } from "redux/action/ActionActions";
import { loadingEnd, loadingStart } from "redux/loading/LoadingActions";
import InputFile from "components/input/InputFile";
import { getAssociateShops } from "redux/shop/AssociateShopReducer";
import Input from "components/input/Input";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const MAP_COLUMN_TO_LABEL = {
  shopIsFranchisee: "가맹점/직영점",
  shopName: "대리점명",
  shopCode: "대리점코드",
  managerAccount: "아이디",
  managerPassword: "비밀번호",
  managerName: "이름",
  managerPhone: "연락처",
  shopBusinessNumber: "사업자등록번호",
  shopPhone: "전화번호",
  shopFax: "팩스번호",
  shopPostCode: "우편번호",
  shopAddress: "주소",
  shopAddressDetail: "상세주소",
  shopDistrict: "찾아오는길",
  shopLatitude: "위도",
  shopLongitude: "경도",
  associateFeeRate: "수수료(%)",
  paymentKrw: "지급 보증금",
  allowKrw: "거래 허용금",
};

const serviceTypeList = [
  { label: "금방금방", value: "GBGB" },
  { label: "BIZ 대리점", value: "BIZ" },
  { label: "금방금방 및 BIZ", value: "GBGB_BIZ" },
];

const bizRoleList = [
  { label: "다이렉트", value: "DIRECT" },
  { label: "위탁", value: "CONSIGNMENT" },
];

export default function MemberShopAdd(props) {
  const dispatch = useDispatch();
  const classes = useStyle();
  const history = useHistory();

  const inputRef = useRef(null);
  const [banks, setBanks] = useState([]);
  const [shop, setShop] = useState({ shopType: "FRANCHISEE" });
  const [images, setImages] = useState(null);

  const { associateShops } = useSelector((state) => state.shop);
  const [cityList, setCityList] = useState([]);
  const [subCityList, setSubCityList] = useState(null);
  const [manageCityId, setManageCityId] = useState({ id: 0, index: -1 });
  const [selectedCities, setSelectedCities] = useState([]);

  useEffect(() => {
    onLoad();
  }, []);

  // manageCityId 변경 시 하위 도시 목록 조회
  useEffect(() => {
    if (manageCityId.id !== 0 && manageCityId.index === 0) {
      APIS.getCity(manageCityId?.id).then(({ data: { success, data } }) => {
        if (success) {
          setSubCityList(data.idNameList);
        }
      });
    }
  }, [manageCityId]);

  const onLoad = async () => {
    dispatch(loadingStart);
    try {
      const [bankResponse, cityResponse] = await Promise.all([
        APIS.getBankList(),
        APIS.getCity(0),
      ]);
      const {
        data: { success: bankSuccess, data: bankData, message: bankMessage },
      } = bankResponse;
      if (bankSuccess) {
        setBanks(bankData);
      } else {
        dispatch(actionError(bankMessage));
      }
      setShop((shop) => ({ ...shop, shopBankCode: bankData[0]["code"] }));

      const {
        data: { success: citySuccess, data: cityData, message: cityMessage },
      } = cityResponse;
      if (citySuccess) {
        setCityList(cityData.idNameList);
      } else {
        dispatch(actionError(cityMessage));
      }
    } catch (err) {
      dispatch(actionError(err));
    } finally {
      dispatch(loadingEnd);
    }
  };

  const handleChangeValue = (key, value) => {
    setShop((shop) => ({ ...shop, [key]: value }));
  };

  const handleClickFindAddress = () => {
    dispatch(
      openModal({
        children: (
          <FindAddress
            callback={({ postCode, address }) => {
              dispatch(closeModal());
              setShop((shop) => ({
                ...shop,
                shopPostCode: postCode,
                shopAddress: address,
                shopAddressDetail: "",
              }));
            }}
          />
        ),
      })
    );
  };

  const handleChangeImage = (file) => {
    if (file) {
      // 파일 확장자 체크
      const fileType = file.name.split(".").pop().toLowerCase();
      if (!["jpg", "jpeg", "png"].includes(fileType)) {
        dispatch(
          actionError("이미지파일(.jpg, .png, .jpeg)만 업로드 가능합니다.")
        );
        return;
      }

      // 파일 크기 체크 (100 KB)
      if (file.size > 100 * 1024) {
        dispatch(
          actionError(
            "첨부 가능한 파일 용량을 초과하였습니다.\n100kb 이하의 파일을 올려주세요."
          )
        );
        return;
      }

      const f = new FileReader();
      f.onload = () => {
        const img = new Image();
        img.src = f.result;

        img.onload = () => {
          // 비율 체크
          if (img.width !== img.height) {
            dispatch(actionError("이미지 비율이 1:1이 아닙니다."));
            return;
          }

          file.uri = f.result;
          setImages(file);
        };
      };

      f.readAsDataURL(file);
    }
  };

  const handleClickRegister = (password) => {
    const errorMessage = validationData();
    if (errorMessage) {
      dispatch(actionOpen(errorMessage, null, null, null, true));
      return false;
    }

    const json = JSON.stringify({ ...shop, password });
    const blob = new Blob([json], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("shop", blob);
    formData.append(`image`, images);

    dispatch(loadingStart);
    APIS.postCreateShop(formData)
      .then(({ data: { success, data, message } }) => {
        if (!success) {
          dispatch(actionOpen(message, null, null, null, true));
        } else {
          dispatch(
            actionOpen(
              "대리점 추가가 완료되었습니다.",
              () => {
                dispatch(getAssociateShops());
                history.goBack();
              },
              null,
              null,
              true
            )
          );
        }
      })
      .finally(() => {
        dispatch(loadingEnd);
      });
  };

  const validationData = () => {
    const {
      shopName,
      managerAccount,
      managerPassword,
      managerName,
      managerPhone,
      shopBusinessNumber,
      shopPhone,
      shopFax,
      shopLatitude,
      shopLongitude,
      associateFeeRate,
      paymentKrw,
      allowKrw,
    } = shop;

    if (!shopName) {
      return "대리점명을 입력해주세요.";
    }

    if (!managerAccount) {
      return "관리자 아이디를 입력해주세요.";
    }

    if (!managerPassword) {
      return "기본정보 - 비밀번호를 입력해주세요.";
    }

    if (!managerName) {
      return "관리자 이름을 입력해주세요.";
    }

    if (!managerPhone) {
      return "관리자 연락처를 확인해주세요.";
    }

    if (!validation("businessNumber", shopBusinessNumber)) {
      return "사업자등록번호를 확인해주세요.";
    }

    // if (!images) {
    //   return "매장 이미지를 등록해 주세요.";
    // }

    // if (!validation("tel", shopPhone)) {
    //   return "대리점 전화번호를 확인해주세요.";
    // }

    // if (!validation("tel", shopFax)) {
    //   return "대리점 팩스번호를 확인해주세요.";
    // }

    // if (!shopLatitude) {
    //   return "위도를 확인해주세요.";
    // }

    // if (!shopLongitude) {
    //   return "경도를 확인해주세요.";
    // }

    if (
      shopType === "ASSOCIATE" &&
      (associateFeeRate < 0 || associateFeeRate > 100)
    ) {
      return "수수료를 확인해주세요.";
    }

    return "";
  };

  const getInputUI = (key, option = {}) => {
    const label = MAP_COLUMN_TO_LABEL[key];
    return {
      label,
      value: shop[key],
      type: "input",
      onChange: (e) => handleChangeValue(key, e),
      ...option,
    };
  };

  const handleChangeCity = (event, index) => {
    const newCityId = event.target.value;

    setManageCityId({
      id: newCityId.id,
      index: index,
    });
    handleChangeValue("manageCityId", newCityId.id);

    if (index === 0) {
      setSelectedCities([
        {
          id: newCityId.id,
          name: newCityId.name,
        },
        { id: "", name: "" },
      ]);
    } else {
      // 두 번째 도시 변경 시
      const updatedCities = [...selectedCities];
      updatedCities[index] = {
        id: newCityId.id,
        name: newCityId.name,
      };
      setSelectedCities(updatedCities);
    }
  };

  const {
    shopType = "FRANCHISEE",
    shopBankAccountNumber = "",
    shopPostCode = "",
    shopAddress = "",
    shopAddressDetail = "",
    shopBankCode = "",
    associateShopId = "",
    serviceType,
    bizServiceRoleList,
    shopCode,
    consignmentErpCode,
    status = "ACTIVE",
  } = shop;

  const ImagePreview = () => {
    return (
      <Flex row>
        {images?.uri ? (
          <ImageFrame src={images?.uri} alt="대리점 이미지" />
        ) : (
          <NoImage />
        )}
        <InputFile accept="image/*" label="등록" callback={handleChangeImage} />
      </Flex>
    );
  };

  return (
    <>
      <h1 style={{ marginLeft: 50 }}>대리점 추가하기</h1>

      <AdminConfirmButton
        popupMessage="등록하시겠습니까?"
        label="등록"
        popupLabel="대리점 등록"
        callback={handleClickRegister}
      />
      <GridRow
        label="기본정보"
        data={[
          { label: "등록일", value: "" },
          {
            label: "대리점 구분",
            type: "radio",
            data: [
              { label: "대리점", value: "FRANCHISEE" },
              { label: "직영점", value: "BRANCH" },
              { label: "제휴사", value: "ASSOCIATE" },
            ],
            value: shopType,
            onChange: (e) => handleChangeValue("shopType", e),
          },
          getInputUI("shopName"),
          // getInputUI("shopCode", { validation: "number" }),
          {
            label: "대리점코드",
            value: "",
            type: "render",
            render: (
              <Flex row style={{ gap: 10 }}>
                <Input
                  value={shopCode}
                  validation={"number"}
                  onChange={(e) => {
                    handleChangeValue("shopCode", e);
                  }}
                />
                <Input
                  value={consignmentErpCode}
                  placeholder={"ERP 대리점 코드"}
                  onChange={(e) => {
                    handleChangeValue("consignmentErpCode", e);
                  }}
                />
              </Flex>
            ),
          },
          getInputUI("managerAccount", { maxLength: 20 }),
          getInputUI("managerPassword", { type: "input-password" }),
          getInputUI("managerName"),
          getInputUI("managerPhone", { validation: "tel" }),
          getInputUI("shopBusinessNumber", { validation: "businessNumber" }),
          {
            label: "정산계좌",
            value: "",
            type: "render",
            render: (
              <Flex row className={classes.addressContainer}>
                <Flex row>
                  <select
                    style={{
                      minWidth: 100,
                      fontSize: 16,
                    }}
                    value={shopBankCode}
                    onChange={(e) => {
                      setShop((shop) => ({
                        ...shop,
                        shopBankCode: e.target.value,
                      }));
                    }}
                  >
                    {banks.map(({ code, name }, i) => {
                      return (
                        <option key={i.toString()} value={code}>
                          {name}
                        </option>
                      );
                    })}
                  </select>
                </Flex>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopBankAccountNumber}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopBankAccountNumber: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
          {
            label: "대리점 이미지",
            type: "render",
            render: (
              <Flex>
                <ImagePreview />
              </Flex>
            ),
          },
          shopType === "ASSOCIATE"
            ? getInputUI("associateFeeRate", { validation: "number" })
            : shopType === "FRANCHISEE"
            ? {
                label: "제휴사",
                value: "",
                type: "render",
                render: (
                  <Flex row className={classes.addressContainer}>
                    <Flex row>
                      <select
                        style={{
                          minWidth: 100,
                          fontSize: 16,
                        }}
                        value={associateShopId}
                        onChange={(e) => {
                          setShop((shop) => ({
                            ...shop,
                            associateShopId: e.target.value,
                          }));
                        }}
                      >
                        <option value={""}>전체</option>
                        {associateShops.map(({ label, value }, i) => {
                          return (
                            <option key={i.toString()} value={value}>
                              {label}
                            </option>
                          );
                        })}
                      </select>
                    </Flex>
                  </Flex>
                ),
              }
            : {},
          shopType === "ASSOCIATE" &&
            getInputUI("paymentKrw", { validation: "number" }),
          shopType === "ASSOCIATE" &&
            getInputUI("allowKrw", { validation: "number" }),
          {
            label: "서비스타입",
            value: "",
            type: "render",
            render: (
              <Flex row style={{ gap: 15 }}>
                {serviceTypeList.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      label={item.label}
                      checked={item.value === serviceType}
                      onChange={(e) => {
                        handleChangeValue("serviceType", item.value);
                      }}
                    />
                  );
                })}
              </Flex>
            ),
          },
          {
            label: "Biz 영역",
            value: "",
            type: "render",
            render: (
              <Flex row style={{ gap: 15 }}>
                {bizRoleList.map((item, index) => {
                  const isChecked = bizServiceRoleList?.includes(item.value);
                  return (
                    <Checkbox
                      key={index}
                      label={item.label}
                      checked={isChecked}
                      onChange={(e) => {
                        setShop((prevShop) => {
                          const updatedBizServiceRole = e.value
                            ? [
                                ...(prevShop.bizServiceRoleList || []),
                                item.value,
                              ]
                            : (prevShop.bizServiceRoleList || []).filter(
                                (role) => role !== item.value
                              );
                          return {
                            ...prevShop,
                            bizServiceRoleList: updatedBizServiceRole,
                          };
                        });
                      }}
                    />
                  );
                })}
              </Flex>
            ),
          },
          (shopType === "FRANCHISEE" || shopType === "BRANCH") && {
            label: "관할도시",
            type: "render",
            render: (
              <Flex row style={{ gap: 10, width: "100%" }}>
                <DropDownList
                  data={cityList}
                  value={cityList.find(
                    (city) => city.id === selectedCities[0]?.id
                  )}
                  textField="name"
                  onChange={(e) => handleChangeCity(e, 0)}
                />
                {subCityList && (
                  <DropDownList
                    data={subCityList}
                    value={subCityList.find(
                      (city) => city.id === selectedCities[1]?.id
                    )}
                    textField="name"
                    onChange={(e) => handleChangeCity(e, 1)}
                  />
                )}
              </Flex>
            ),
          },
          {
            label: "대리점 활성화",
            type: "radio",
            data: [
              { label: "활성화", value: "ACTIVE" },
              { label: "비활성화", value: "DISABLED" },
            ],
            value: status,
            onChange: (e) => handleChangeValue("status", e),
          },
        ].filter((item) => Object.keys(item).length > 0)}
      />

      <GridRow
        label="대리점정보"
        data={[
          getInputUI("shopPhone", { validation: "tel" }),
          getInputUI("shopFax", { validation: "tel" }),
          {
            label: "지역",
            value: "",
            type: "render",
            fullWidth: true,
            render: (
              <Flex row className={classes.addressContainer}>
                <Button label="주소찾기" onClick={handleClickFindAddress}>
                  주소찾기
                </Button>

                <span
                  className={classes.addressWrap}
                  onClick={handleClickFindAddress}
                >
                  {shopPostCode && `(${shopPostCode}) `}
                  {shopAddress}
                </span>
                <InputBase
                  className={classes.addressDetailWrap}
                  value={shopAddressDetail}
                  onChange={({ target: { value } }) => {
                    setShop((shop) => ({
                      ...shop,
                      shopAddressDetail: value,
                    }));
                  }}
                />
              </Flex>
            ),
          },
          getInputUI("shopDistrict", { fullWidth: true, inputFullWidth: true }),
          getInputUI("shopLatitude", { validation: "number" }),
          getInputUI("shopLongitude", { validation: "number" }),
        ]}
      />
    </>
  );
}

const useStyle = makeStyles({
  addressWrap: {
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: "0 10px",
    minHeight: 20,
    cursor: "pointer",
  },
  addressDetailWrap: {
    flex: 1,
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: 5,
    minHeight: 20,
  },
  icon: { cursor: "pointer" },
  addressContainer: { flex: 1, alignSelf: "stretch" },
  history1Toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 50px",
    marginTop: 50,
  },
  history1Select: {
    border: "1px solid rgba(0,0,0,0.23)",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  history1buttonRadios: {
    margin: "0px 50px",
    marginTop: 20,
  },
  td: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  td2: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      borderRight: "1px solid rgba(0,0,0,0.23) !important",
      borderBottom: "1px solid rgba(0,0,0,0.23) !important",
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  history2ButtonText: {
    color: "black",
    borderBottom: "1px solid black",
  },
});

const ImageFrame = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

const NoImage = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;
