import { type GetUserAssetData } from "libs/apis";
import { useStyle } from "../style";
import { numberWithCommas } from "libs/utils";

interface UserBalanceStatusProps {
  userAssetStatus: GetUserAssetData[] | undefined;
}

const UserBalanceStatus = (props: UserBalanceStatusProps) => {
  const { userAssetStatus } = props;
  console.log(userAssetStatus);
  const classes = useStyle();
  return (
    <table cellPadding={0} cellSpacing={0} className={classes.table}>
      <tbody>
        <tr>
          <th></th>
          {userAssetStatus?.map((item) => (
            <th key={item.sumDate}>{item.sumDate}</th>
          ))}
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>KRW</td>
          {userAssetStatus?.map((item) => (
            <td key={item.krwUserAsset}>
              {numberWithCommas(item.krwUserAsset)}
            </td>
          ))}
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>금</td>
          {userAssetStatus?.map((item) => (
            <td key={item.goldUserAsset}>
              {numberWithCommas(item.goldUserAsset)}
            </td>
          ))}
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>은</td>
          {userAssetStatus?.map((item) => (
            <td key={item.silverUserAsset}>
              {numberWithCommas(item.silverUserAsset)}
            </td>
          ))}
        </tr>
        <tr>
          <td style={{ textAlign: "center" }}>Point</td>
          {userAssetStatus?.map((item) => (
            <td key={item.pointUserAsset}>
              {numberWithCommas(item.pointUserAsset)}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default UserBalanceStatus;
