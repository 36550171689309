import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Flex from "../../../components/flex/Flex";
import SearchLayout, { SearchItem } from "../../../layouts/search/SearchLayout";
import GridTable, {
  CenterCell,
  KendoCell,
  NumberCell,
} from "../../../components/gird-table/GridTable";
import { GridColumn } from "@progress/kendo-react-grid";
import TextInput from "../../../components/input/TextInput";
import withProgressBar from "../../../hoc/withProgressBar";
import KendoButton from "../../../components/button/KendoButton";
import * as APIS from "../../../libs/apis";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { formatTime, numberWithCommas } from "../../../libs/utils";
import { Checkbox } from "@progress/kendo-react-inputs";
import DropDownList from "../../../components/select/DropDownList";
import DateInput from "../../../components/input/DateInput";
import cloneDeep from "lodash.clonedeep";
import { actionError, actionOpen } from "../../../redux/action/ActionActions";
import { objToQueryStr } from "../../../services/utils";
import StyledTable from "../../../components/table/StyledTable";

const gridName = "InventoryEventReceiving";

const SELECTED_FIELD = "selected";
const assetTypeList = [
  { label: "금", value: "GOLD" },
  { label: "은", value: "SILVER" },
  { label: "포인트", value: "POINT" },
];

const INIT_SEARCH_STATE = {
  dateType: "CREATED",
  startDate: "",
  endDate: "",
  couponStockStatus: "", //입고구분
  // couponStatus: "", //구분
  couponType: "", //이벤트/쿠폰
  eventName: "", //내용
  assetType: "", //자산
};

const InventoryEventReceiving = withProgressBar(({ progressBar }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const locationState = history.location.state || {};
  const { page, size = 100 } = locationState;
  const [total, setTotal] = useState(0);
  const [searchState, setSearchState] = useState(INIT_SEARCH_STATE);
  const [idList, setIdList] = useState([]); // 체크리스트
  const [gridData, setGridData] = useState([]);
  const [assetSummary, setAssetSummary] = useState([]);
  const [rawSummary, setRawSummary] = useState([]);

  useEffect(() => {
    if (locationState.page || locationState.page === 0) {
      getEventReceiving({ ...locationState, size });
      getSummary();
      getRawSammary();
    }
  }, [locationState]);

  const {
    dateType,
    startDate,
    endDate,
    couponStockStatus, //입고구분
    // couponStatus, //구분
    couponType, //이벤트/쿠폰
    eventName, //내용
    assetType, //자산
  } = searchState;

  const getEventReceiving = (param) => {
    setGridData([]);
    setTotal(0);
    progressBar.on();
    APIS.getInventoryEvent(objToQueryStr(param))
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setGridData(
            data.content.map((item, index) => {
              return {
                ...item,
                no: data.totalElements - (page * size + index),
                // couponStatus:
                //   item.couponStatus
                //     ?.replace("MINT", "발행")
                //     ?.replace("REGISTER", "등록")
                //     ?.replace("PAYMENT", "지급") || "-",
                couponStockStatus:
                  item.couponStockStatus
                    ?.replace("WAIT", "입고대기")
                    ?.replace("STOCKED", "입고완료") || "-",
                couponType:
                  item.couponType
                    ?.replace("EVENT", "이벤트")
                    ?.replace("COUPON", "쿠폰") || "-",
                stockedAt: item.stockedAt
                  ? formatTime(item.stockedAt, "YYYY-MM-DD")
                  : "-",
                paidDt: item.paidDt || "-",
                assetType:
                  item.assetType
                    .replace("GOLD", "금")
                    .replace("SILVER", "은")
                    .replace("POINT", "포인트") || "-",
              };
            })
          );
          setTotal(data.totalElements);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };

  const getSummary = () => {
    progressBar.on();
    APIS.getInventoryEventAsset()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setAssetSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };

  const getRawSammary = () => {
    progressBar.on();
    APIS.getInventoryPresentByStockType()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setRawSummary(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .finally(progressBar.off);
  };

  const handleChangeSearchState = (newState) => {
    setSearchState({ ...searchState, ...newState });
  };

  const handleChangeLocationState = (newState) => {
    history.replace(history.location.pathname, {
      ...locationState,
      ...newState,
    });
  };

  const handleClickSearch = () => {
    handleChangeLocationState({ ...searchState, page: 0 });
  };

  const getStocksExcel = (param) => {
    delete param.page;
    delete param.size;

    progressBar.on();
    APIS.getInventoryExcel(objToQueryStr(param))
      .then(({ data }) => {
        const blob = new Blob([data]);
        saveAs(
          blob,
          `이벤트입고_${formatTime(new Date(), "YYYYMMDDHHmmSS")}.xlsx`
        );
      })
      .finally(progressBar.off);
  };

  const handleClickExcel = () => {
    getStocksExcel({ ...locationState });
  };

  const handleClickReceiving = () => {
    if (idList.length === 0) {
      dispatch(actionError("입고할 항목을 선택하여 주세요."));
      return;
    }

    const filtered = gridData.filter(({ id }) => idList.includes(id));
    progressBar.on();

    Promise.all(filtered.map(({ id }) => APIS.postInventoryStocked([{ id }])))
      .then((responses) => {
        const failedResponses = responses.filter(({ data }) => !data.success);
        const successResponses = responses.filter(({ data }) => data.success);

        const successIds = successResponses
          .map(({ config }) => {
            try {
              const parsedData = JSON.parse(config.data);
              return parsedData[0].id;
            } catch (error) {
              console.error(error, "error");
              return null;
            }
          })
          .filter(Boolean);

        const newIdList = idList.filter((id) => !successIds.includes(id));
        setIdList(newIdList);

        const errorMap = failedResponses.reduce((acc, { data }) => {
          if (data?.message) {
            acc[data.message] = (acc[data.message] || 0) + 1;
          }
          return acc;
        }, {});

        const errorMessages = Object.entries(errorMap)
          .map(([message, count]) =>
            count > 1 ? `${message} (${count}건)` : message
          )
          .join("\n");

        const successCount = successResponses.length;
        const failedCount = failedResponses.length;
        let message = `[입고 처리 결과]\n`;
        if (successCount > 0) message += `성공: ${successCount}건\n`;
        if (failedCount > 0)
          message += `실패: ${failedCount}건\n${errorMessages}`;

        dispatch(
          actionOpen(
            message,
            () => {
              getEventReceiving({ ...locationState, size });
              getSummary();
              getRawSammary();
            },
            null,
            null,
            true
          )
        );
      })
      .catch((err) => dispatch(actionError(err)))
      .finally(() => {
        progressBar.off();
      });
  };

  return (
    <Container>
      <SearchLayout
        title="이벤트입고"
        onSearch={handleClickSearch}
        onReset={() => handleChangeSearchState(INIT_SEARCH_STATE)}>
        <Flex row>
          <SearchItem header="조회기간">
            <DropDownList
              options={[
                { label: "지급일", value: "CREATED" },
                { label: "입고일", value: "UPDATED" },
              ]}
              selected={dateType}
              onChange={(dateType) => handleChangeSearchState({ dateType })}
              style={{ width: 100 }}
            />
            <DateInput
              value={startDate}
              onChange={(startDate) => handleChangeSearchState({ startDate })}
              max={formatTime()}
            />{" "}
            ~{" "}
            <DateInput
              value={endDate}
              onChange={(endDate) => handleChangeSearchState({ endDate })}
              min={startDate}
            />
          </SearchItem>
          <SearchItem header="자산">
            <DropDownList
              useAll
              options={assetTypeList}
              selected={assetType}
              onChange={(assetType) => handleChangeSearchState({ assetType })}
            />
          </SearchItem>
          {/* <SearchItem header="구분">
            <DropDownList
              useAll
              options={[
                { label: "발행", value: "MINT" },
                { label: "등록", value: "REGISTER" },
                { label: "지급", value: "PAYMENT" },
              ]}
              selected={couponStatus}
              onChange={(couponStatus) =>
                handleChangeSearchState({ couponStatus })
              }
              style={{ width: 100 }}
            />
          </SearchItem> */}
        </Flex>
        <Flex row>
          <SearchItem header="이벤트/쿠폰">
            <DropDownList
              useAll
              options={[
                { label: "이벤트", value: "EVENT" },
                { label: "쿠폰", value: "COUPON" },
              ]}
              selected={couponType}
              onChange={(couponType) => handleChangeSearchState({ couponType })}
            />
          </SearchItem>
          <SearchItem header="입고구분">
            <DropDownList
              useAll
              options={[
                { label: "입고대기", value: "WAIT" },
                { label: "입고완료", value: "STOCKED" },
              ]}
              selected={couponStockStatus}
              onChange={(couponStockStatus) =>
                handleChangeSearchState({ couponStockStatus })
              }
            />
          </SearchItem>
        </Flex>
        <Flex row>
          <SearchItem header="내용">
            <TextInput
              value={eventName}
              onChange={(eventName) => {
                handleChangeSearchState({ eventName });
              }}
            />
          </SearchItem>
          <SearchItem header="" />
        </Flex>
      </SearchLayout>
      <Flex row style={{ justifyContent: "space-between", marginTop: 10 }}>
        <Flex row style={{ alignItems: "center" }}>
          <span
            className="title"
            style={{ marginRight: 5, fontFamily: "NotoSansKR-Regular" }}>
            자산 잔고
          </span>
          <StyledTable className="summary">
            <Flex row>
              <Flex className="header">KRW</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(assetSummary.krw)}
              </Flex>
              <Flex className="header">POINT</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(assetSummary.point)}
              </Flex>
              <Flex className="header">GOLD</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(assetSummary.gold)}
              </Flex>
              <Flex className="header">SILVER</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(assetSummary.silver)}
              </Flex>
            </Flex>
          </StyledTable>
          <span
            className="title"
            style={{
              margin: "0px 5px 0px 10px",
              fontFamily: "NotoSansKR-Regular",
            }}>
            원재료 재고수량
          </span>
          <StyledTable className="summary">
            <Flex row>
              <Flex className="header">GOLD</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(
                  rawSummary.find((item) => item.goldOrSilver === "GOLD")?.raws
                )}
              </Flex>
              <Flex className="header">SILVER</Flex>
              <Flex
                className="data"
                style={{
                  width: 130,
                  justifyContent: "right",
                  fontFamily: "NotoSansKR-Medium",
                }}>
                {numberWithCommas(
                  rawSummary.find((item) => item.goldOrSilver === "SILVER")
                    ?.raws
                )}
              </Flex>
            </Flex>
          </StyledTable>
        </Flex>
        <Flex row>
          <KendoButton icon="excel" label="Excel" onClick={handleClickExcel} />
          <KendoButton
            label="입고"
            onClick={handleClickReceiving}
            style={{ marginLeft: 10 }}
          />
        </Flex>
      </Flex>
      <GridTable
        className="grid"
        style={{ height: 580 }}
        data={gridData}
        name={gridName}
        reorderable
        scrollable
        pageable
        skip={page * size}
        take={size}
        total={total}
        onPageChange={({ page: { skip, take: size } }) => {
          const page = skip / size;
          if (locationState.page !== page || locationState.size !== size) {
            handleChangeLocationState({ page, size });
          }
        }}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
        }}>
        <GridColumn
          width="40px"
          field={SELECTED_FIELD}
          headerCell={() => {
            return (
              <div>
                <Checkbox
                  checked={
                    gridData.filter(
                      (item) =>
                        item.couponStockStatus === "입고대기" &&
                        item.assetType !== "포인트"
                    )?.length === idList.length &&
                    gridData.length !== 0 &&
                    idList.length !== 0
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    const { ariaChecked } = e.syntheticEvent.target;
                    if (ariaChecked === "true") {
                      setIdList([]);
                    } else {
                      setIdList([
                        ...gridData
                          .filter(
                            (item) =>
                              item.couponStockStatus === "입고대기" &&
                              item.assetType !== "포인트"
                          )
                          .map((item) => item.id),
                      ]);
                    }
                  }}
                />
              </div>
            );
          }}
          cell={(props) => {
            const { dataItem } = props;
            const data = dataItem["id"];
            return (
              <td style={{ textAlign: "center" }}>
                {dataItem["assetType"] !== "포인트" &&
                  dataItem["couponStockStatus"] === "입고대기" && (
                    <Checkbox
                      checked={idList.includes(data) ? true : false}
                      onChange={() => {
                        let parseList = cloneDeep(idList);
                        if (idList.includes(data)) {
                          const result = parseList.filter(
                            (item) => item !== data
                          );
                          setIdList(result);
                        } else {
                          parseList.push(data);
                          setIdList(parseList);
                        }
                      }}
                    />
                  )}
              </td>
            );
          }}
        />
        <GridColumn title="No" field="no" width={80} cell={CenterCell} />
        <GridColumn title="지급일" field="paidDt" cell={CenterCell} />
        {/* <GridColumn
          title="구분"
          field="couponStatus"
          width={80}
          cell={CenterCell}
        /> */}
        <GridColumn title="내용" field="eventName" />
        <GridColumn
          title="이벤트/쿠폰"
          field="couponType"
          width={100}
          cell={CenterCell}
        />
        <GridColumn
          title="자산"
          field="assetType"
          cell={CenterCell}
          width={100}
        />
        <GridColumn title="수량" field="gram" cell={NumberCell} />
        <GridColumn title="포인트" field="point" cell={NumberCell} />
        <GridColumn
          title="지급건수"
          field="count"
          cell={NumberCell}
          width={100}
        />
        <GridColumn title="입고" field="couponStockStatus" cell={CenterCell} />
        <GridColumn title="입고일" field="stockedAt" cell={CenterCell} />
      </GridTable>
    </Container>
  );
});

export default InventoryEventReceiving;

const Container = styled(Flex)`
  flex: 1;
  padding: 10px;
`;
