import { makeStyles } from "@material-ui/core";
import styled from "styled-components";

export const useStyle = makeStyles({
  addressWrap: {
    minWidth: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: "0 10px",
    minHeight: 20,
    cursor: "pointer",
  },
  addressDetailWrap: {
    flex: 1,
    border: "1px solid rgba(0,0,0,0.23)",
    borderRadius: 3,
    marginLeft: 10,
    padding: 5,
    minHeight: 20,
  },
  icon: { cursor: "pointer" },
  addressContainer: { flex: 1, alignSelf: "stretch" },
  history1Toolbar: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0px 50px",
    marginTop: 50,
  },
  history1Select: {
    border: "1px solid rgba(0,0,0,0.23)",
  },
  input: {
    width: 200,
    padding: "0px 10px",
    border: "1px solid rgba(0,0,0,0.23)",
  },
  button: {
    backgroundColor: "#000",
    width: 100,
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
  },
  history1buttonRadios: {
    margin: "0px 50px",
    marginTop: 20,
  },
  td: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  td2: {
    borderRight: "none !important",
    borderBottom: "none !important",
    margin: "20px 50px 30px 50px",
    "& th": {
      borderRight: "1px solid rgba(0,0,0,0.23) !important",
      borderBottom: "1px solid rgba(0,0,0,0.23) !important",
      padding: "6px",
    },
    "& td": {
      textAlign: "center",
      padding: "10px",
      borderRight: "1px solid rgba(0,0,0,0.23)",
      borderBottom: "1px solid rgba(0,0,0,0.23)",
    },
  },
  history2ButtonText: {
    color: "black",
    borderBottom: "1px solid black",
  },
  labelTabs: {
    margin: "0px 50px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
  },
  numericInput: {
    width: "42%",
    alignSelf: "stretch",
    borderRadius: "3px",
    border: "1px solid rgba(0,0,0,0.23)",
    color: "black",
  },
});

export const ImageFrame = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

export const NoImage = styled.div`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0.23);
`;
