import { useEffect, useRef, useState } from "react";

import {
  PanelBar,
  PanelBarItem,
  TileLayout,
} from "@progress/kendo-react-layout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as APIS from "../../libs/apis";
import { STORAGE_KEY } from "../../libs/consts";
import routes from "../../libs/routes";
import { formatTime, numberWithCommas } from "../../libs/utils";
import { actionError } from "../../redux/action/ActionActions";
import { loadingEnd, loadingStart } from "../../redux/loading/LoadingActions";
import UserBalanceStatus from "./components/UserBalanceStatus";
import { Panel, useStyle } from "./style";

const StatusDashBoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyle();
  const [positionData, setPositionData] = useState(
    JSON.parse(localStorage.getItem(STORAGE_KEY.dashBoard))?.dashboard || []
  );
  const [processingStatus, setProcessingStatus] = useState();
  const [inquiryStatus, setInquiryStatus] = useState();
  const [userStatus, setUserStatus] = useState();
  const [userFeeStatus, setUserFeeStatus] = useState();
  const [franchiseeFeeStatus, setFranchiseeFeeStatus] = useState();
  const [userAssetStatus, setUserAssetStatus] = useState();
  const [expanded, setExpanded] = useState([]);
  const checkApi = useRef(true);
  const [loading, setLoading] = useState(true);
  const initData = [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
    {
      col: 1,
      colSpan: 4,
      rowSpan: 1,
    },
  ];

  const accordionContent = [
    {
      header: "회원현황",
      body: (
        <table cellPadding={0} cellSpacing={0} className={classes.table}>
          <tr>
            <th colSpan={3}>가입(일일)</th>
            <th colSpan={2}>활동(일일)</th>
            <th rowSpan={2}>MAU</th>
            <th rowSpan={2}>탈퇴</th>
            <th colSpan={5}>누적</th>
          </tr>
          <tr>
            <th>추천</th>
            <th>비추천</th>
            <th>합계</th>
            <th>방문자</th>
            <th>방문횟수</th>
            <th>가입</th>
            <th>탈퇴</th>
            <th>사용정지</th>
            <th>휴면</th>
            <th>순회원</th>
          </tr>
          <tr>
            <td>{numberWithCommas(userStatus?.recommendSignUp)}</td>
            <td>{numberWithCommas(userStatus?.notRecommendSignUp)}</td>
            <td>
              {numberWithCommas(
                userStatus?.recommendSignUp + userStatus?.notRecommendSignUp
              )}
            </td>
            <td>{numberWithCommas(userStatus?.dau)}</td>
            <td>{numberWithCommas(userStatus?.todayVisitCount)}</td>
            <td>{numberWithCommas(userStatus?.mau)}</td>
            <td>{numberWithCommas(userStatus?.leaveUserCount)}</td>
            <td>{numberWithCommas(userStatus?.totalSignUp)}</td>
            <td>{numberWithCommas(userStatus?.totalLeaveUserCount)}</td>
            <td>{numberWithCommas(userStatus?.totalInactiveUserCount)}</td>
            <td>{numberWithCommas(userStatus?.totalDormantUserCount)}</td>
            <td>
              {numberWithCommas(
                userStatus?.totalSignUp -
                  userStatus?.totalLeaveUserCount -
                  userStatus?.totalInactiveUserCount -
                  userStatus?.totalDormantUserCount
              )}
            </td>
          </tr>
        </table>
      ),
    },
    {
      header: "자산현황",
      body: <UserBalanceStatus userAssetStatus={userAssetStatus} />,
    },
    {
      header: "회원 수수료 현황",
      body: (
        <table cellPadding={0} cellSpacing={0} className={classes.table}>
          <tr>
            <th colSpan={1}></th>
            <th colSpan={4}>매수/매도</th>
            <th rowSpan={2}>실물인출</th>
            <th colSpan={3}>안심직거래</th>
            <th rowSpan={2}>출금</th>
            <th rowSpan={2}>합계</th>
          </tr>
          <tr>
            <th></th>
            <th>금 매수</th>
            <th>금 매도</th>
            <th>은 매수</th>
            <th>은 매도</th>
            <th>판매</th>
            <th>구매</th>
            <th>위약/감정</th>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{"금일 (원)"}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.goldBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.goldSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.silverBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.silverSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.goods)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.safeTradeSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.today?.safeTradeBuy)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.today?.safeTradePenaltyAndAuthPrice
              )}
            </td>
            <td>{numberWithCommas(userFeeStatus?.today?.withdraw)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.today?.goldBuy +
                  userFeeStatus?.today?.goldSell +
                  userFeeStatus?.today?.silverBuy +
                  userFeeStatus?.today?.silverSell +
                  userFeeStatus?.today?.goods +
                  userFeeStatus?.today?.safeTradeSell +
                  userFeeStatus?.today?.safeTradeBuy +
                  userFeeStatus?.today?.safeTradePenaltyAndAuthPrice +
                  userFeeStatus?.today?.withdraw
              )}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{"전일 (원)"}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.goldBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.goldSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.silverBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.silverSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.goods)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.safeTradeSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.safeTradeBuy)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.yesterday?.safeTradePenaltyAndAuthPrice
              )}
            </td>
            <td>{numberWithCommas(userFeeStatus?.yesterday?.withdraw)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.yesterday?.goldBuy +
                  userFeeStatus?.yesterday?.goldSell +
                  userFeeStatus?.yesterday?.silverBuy +
                  userFeeStatus?.yesterday?.silverSell +
                  userFeeStatus?.yesterday?.goods +
                  userFeeStatus?.yesterday?.safeTradeSell +
                  userFeeStatus?.yesterday?.safeTradeBuy +
                  userFeeStatus?.yesterday?.safeTradePenaltyAndAuthPrice +
                  userFeeStatus?.yesterday?.withdraw
              )}
            </td>
          </tr>
          <tr style={{ color: "#ff6e6e" }}>
            <td style={{ textAlign: "center" }}>{"금월 (원)"}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.goldBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.goldSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.silverBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.silverSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.goods)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.safeTradeSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.safeTradeBuy)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.thisMonth?.safeTradePenaltyAndAuthPrice
              )}
            </td>
            <td>{numberWithCommas(userFeeStatus?.thisMonth?.withdraw)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.thisMonth?.goldBuy +
                  userFeeStatus?.thisMonth?.goldSell +
                  userFeeStatus?.thisMonth?.silverBuy +
                  userFeeStatus?.thisMonth?.silverSell +
                  userFeeStatus?.thisMonth?.goods +
                  userFeeStatus?.thisMonth?.safeTradeSell +
                  userFeeStatus?.thisMonth?.safeTradeBuy +
                  userFeeStatus?.thisMonth?.safeTradePenaltyAndAuthPrice +
                  userFeeStatus?.thisMonth?.withdraw
              )}
            </td>
          </tr>
          <tr style={{ color: "#ff6e6e" }}>
            <td style={{ textAlign: "center" }}>{"전월 (원)"}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.goldBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.goldSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.silverBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.silverSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.goods)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.safeTradeSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.safeTradeBuy)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.prevMonth?.safeTradePenaltyAndAuthPrice
              )}
            </td>
            <td>{numberWithCommas(userFeeStatus?.prevMonth?.withdraw)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.prevMonth?.goldBuy +
                  userFeeStatus?.prevMonth?.goldSell +
                  userFeeStatus?.prevMonth?.silverBuy +
                  userFeeStatus?.prevMonth?.silverSell +
                  userFeeStatus?.prevMonth?.goods +
                  userFeeStatus?.prevMonth?.safeTradeSell +
                  userFeeStatus?.prevMonth?.safeTradeBuy +
                  userFeeStatus?.prevMonth?.safeTradePenaltyAndAuthPrice +
                  userFeeStatus?.prevMonth?.withdraw
              )}
            </td>
          </tr>
          <tr style={{ color: "red" }}>
            <td style={{ textAlign: "center" }}>{"누적 (원)"}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.goldBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.goldSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.silverBuy)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.silverSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.goods)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.safeTradeSell)}</td>
            <td>{numberWithCommas(userFeeStatus?.cum?.safeTradeBuy)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.cum?.safeTradePenaltyAndAuthPrice
              )}
            </td>
            <td>{numberWithCommas(userFeeStatus?.cum?.withdraw)}</td>
            <td>
              {numberWithCommas(
                userFeeStatus?.cum?.goldBuy +
                  userFeeStatus?.cum?.goldSell +
                  userFeeStatus?.cum?.silverBuy +
                  userFeeStatus?.cum?.silverSell +
                  userFeeStatus?.cum?.goods +
                  userFeeStatus?.cum?.safeTradeSell +
                  userFeeStatus?.cum?.safeTradeBuy +
                  userFeeStatus?.cum?.safeTradePenaltyAndAuthPrice +
                  userFeeStatus?.cum?.withdraw
              )}
            </td>
          </tr>
        </table>
      ),
    },
    {
      header: "가맹점 수수료 현황",
      body: (
        <table cellPadding={0} cellSpacing={0} className={classes.table}>
          <tr>
            <th colSpan={1}></th>
            <th colSpan={4} style={{ textAlign: "center" }}>
              매수/매도
            </th>
            <th rowSpan={2} style={{ textAlign: "center" }}>
              실물인출
            </th>
            <th colSpan={4} style={{ textAlign: "center" }}>
              안심직거래
            </th>
            <th rowSpan={2} style={{ textAlign: "center" }}>
              합계
            </th>
          </tr>
          <tr>
            <th></th>
            <th>금 추천</th>
            <th>금 감정</th>
            <th>은 추천</th>
            <th>은 감정</th>
            <th>판매추천</th>
            <th>판매접수</th>
            <th>구매추천</th>
            <th>구매출고</th>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{"전회 (원)"}</td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.goldRecommend)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.goldAppraisal)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.silverRecommend)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.silverAppraisal)}
            </td>
            <td>{numberWithCommas(franchiseeFeeStatus?.latest?.goods)}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latest?.safeTradeSellRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.safeTradeSellIn)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latest?.safeTradeBuyRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.latest?.safeTradeBuyOut)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latest?.goldRecommend +
                  franchiseeFeeStatus?.latest?.goldAppraisal +
                  franchiseeFeeStatus?.latest?.silverRecommend +
                  franchiseeFeeStatus?.latest?.silverAppraisal +
                  franchiseeFeeStatus?.latest?.goods +
                  franchiseeFeeStatus?.latest?.safeTradeSellRecommend +
                  franchiseeFeeStatus?.latest?.safeTradeSellIn +
                  franchiseeFeeStatus?.latest?.safeTradeBuyRecommend +
                  franchiseeFeeStatus?.latest?.safeTradeBuyOut
              )}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>{"전전회 (원)"}</td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.goldRecommend)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.goldAppraisal)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.silverRecommend)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.silverAppraisal)}
            </td>
            <td>{numberWithCommas(franchiseeFeeStatus?.previous?.goods)}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previous?.safeTradeSellRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.safeTradeSellIn)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previous?.safeTradeBuyRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previous?.safeTradeBuyOut)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previous?.goldRecommend +
                  franchiseeFeeStatus?.previous?.goldAppraisal +
                  franchiseeFeeStatus?.previous?.silverRecommend +
                  franchiseeFeeStatus?.previous?.silverAppraisal +
                  franchiseeFeeStatus?.previous?.goods +
                  franchiseeFeeStatus?.previous?.safeTradeSellRecommend +
                  franchiseeFeeStatus?.previous?.safeTradeSellIn +
                  franchiseeFeeStatus?.previous?.safeTradeBuyRecommend +
                  franchiseeFeeStatus?.previous?.safeTradeBuyOut
              )}
            </td>
          </tr>
          <tr style={{ color: "#ff6e6e" }}>
            <td style={{ textAlign: "center" }}>{"전월 (원)"}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.goldRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.goldAppraisal
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.silverRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.silverAppraisal
              )}
            </td>
            <td>{numberWithCommas(franchiseeFeeStatus?.latestMonth?.goods)}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.safeTradeSellRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.safeTradeSellIn
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.safeTradeBuyRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.safeTradeBuyOut
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.latestMonth?.goldRecommend +
                  franchiseeFeeStatus?.latestMonth?.goldAppraisal +
                  franchiseeFeeStatus?.latestMonth?.silverRecommend +
                  franchiseeFeeStatus?.latestMonth?.silverAppraisal +
                  franchiseeFeeStatus?.latestMonth?.goods +
                  franchiseeFeeStatus?.latestMonth?.safeTradeSellRecommend +
                  franchiseeFeeStatus?.latestMonth?.safeTradeSellIn +
                  franchiseeFeeStatus?.latestMonth?.safeTradeBuyRecommend +
                  franchiseeFeeStatus?.latestMonth?.safeTradeBuyOut
              )}
            </td>
          </tr>
          <tr style={{ color: "#ff6e6e" }}>
            <td style={{ textAlign: "center" }}>{"전전월 (원)"}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.goldRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.goldAppraisal
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.silverRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.silverAppraisal
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.previousMonth?.goods)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.safeTradeSellRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.safeTradeSellIn
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.safeTradeBuyRecommend
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.safeTradeBuyOut
              )}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.previousMonth?.goldRecommend +
                  franchiseeFeeStatus?.previousMonth?.goldAppraisal +
                  franchiseeFeeStatus?.previousMonth?.silverRecommend +
                  franchiseeFeeStatus?.previousMonth?.silverAppraisal +
                  franchiseeFeeStatus?.previousMonth?.goods +
                  franchiseeFeeStatus?.previousMonth?.safeTradeSellRecommend +
                  franchiseeFeeStatus?.previousMonth?.safeTradeSellIn +
                  franchiseeFeeStatus?.previousMonth?.safeTradeBuyRecommend +
                  franchiseeFeeStatus?.previousMonth?.safeTradeBuyOut
              )}
            </td>
          </tr>
          <tr style={{ color: "red" }}>
            <td style={{ textAlign: "center" }}>{"누적 (원)"}</td>
            <td>{numberWithCommas(franchiseeFeeStatus?.cum?.goldRecommend)}</td>
            <td>{numberWithCommas(franchiseeFeeStatus?.cum?.goldAppraisal)}</td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.cum?.silverRecommend)}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.cum?.silverAppraisal)}
            </td>
            <td>{numberWithCommas(franchiseeFeeStatus?.cum?.goods)}</td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.cum?.safeTradeSellRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.cum?.safeTradeSellIn)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.cum?.safeTradeBuyRecommend
              )}
            </td>
            <td>
              {numberWithCommas(franchiseeFeeStatus?.cum?.safeTradeBuyOut)}
            </td>
            <td>
              {numberWithCommas(
                franchiseeFeeStatus?.cum?.goldRecommend +
                  franchiseeFeeStatus?.cum?.goldAppraisal +
                  franchiseeFeeStatus?.cum?.silverRecommend +
                  franchiseeFeeStatus?.cum?.silverAppraisal +
                  franchiseeFeeStatus?.cum?.goods +
                  franchiseeFeeStatus?.cum?.safeTradeSellRecommend +
                  franchiseeFeeStatus?.cum?.safeTradeSellIn +
                  franchiseeFeeStatus?.cum?.safeTradeBuyRecommend +
                  franchiseeFeeStatus?.cum?.safeTradeBuyOut
              )}
            </td>
          </tr>
        </table>
      ),
    },
  ];

  const fetchList = async () => {
    dispatch(loadingStart);
    const processingStatus = APIS.getProcessingStatus()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setProcessingStatus(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
    const inquiryStatus = APIS.getInquiryStatus()
      .then(({ data: { success, data, message } }) => {
        if (success) {
          setInquiryStatus(data);
        } else {
          dispatch(actionError(message));
        }
      })
      .catch((err) => dispatch(actionError(err)));
    Promise.all([processingStatus, inquiryStatus]).finally(() => {
      dispatch(loadingEnd);
    });
  };

  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  useEffect(() => {
    if (expanded.length > 0) {
      if (checkApi.current) {
        const userStatus = APIS.getUserStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setUserStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)));
        const userFeeStatus = APIS.getUserFeeStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setUserFeeStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)));
        const franchiseeFeeStatus = APIS.getFranchiseeFeeStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setFranchiseeFeeStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)));
        const userAssetStatus = APIS.getUserAssetStatus()
          .then(({ data: { success, data, message } }) => {
            if (success) {
              setUserAssetStatus(data);
            } else {
              dispatch(actionError(message));
            }
          })
          .catch((err) => dispatch(actionError(err)));

        Promise.all([
          userStatus,
          userFeeStatus,
          franchiseeFeeStatus,
          userAssetStatus,
        ]).finally(() => {
          checkApi.current = false;
          setTimeout(() => {
            setLoading(false);
          }, 500);
        });
      }
    }
  }, [expanded]);

  const handleReposition = (e) => {
    const dashboardData =
      JSON.parse(localStorage.getItem(STORAGE_KEY.dashBoard)) || {};
    dashboardData["dashboard"] = e.value;
    setPositionData(e.value);
    localStorage.setItem(STORAGE_KEY.dashBoard, JSON.stringify(dashboardData));
  };

  return (
    <>
      <div className={classes.wrap}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.label}>처리현황</div>
          <span
            class="k-icon k-i-arrow-rotate-cw"
            style={{
              alignSelf: "center",
              fontSize: "x-large",
              cursor: "pointer",
            }}
            onClick={() => {
              fetchList();
            }}
          ></span>
        </div>
        <table cellPadding={0} cellSpacing={0} className={classes.table}>
          <tr>
            <th colSpan={3}>출금</th>
            <th colSpan={2}>감정평가</th>
            <th colSpan={1}>실물인출</th>
            <th colSpan={4}>안심직거래</th>
          </tr>
          <tr>
            <th>승인대기</th>
            <th>펌뱅킹 실패</th>
            <th>출금실패</th>
            <th>승인대기</th>
            <th>반송대기</th>
            <th>결제완료</th>
            <th>접수완료</th>
            <th>감정대기</th>
            <th>판매요청대기</th>
            <th>결제완료</th>
          </tr>
          <tr>
            <td style={{ textAlign: "right" }}>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.settlementOutmoney,
                    state: {
                      status: ["MANAGER_WAIT"],
                      orderBy: "DESC",
                      page: 0,
                      size: 10,
                      tab: "1",
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.withdrawal?.managerWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.settlementOutmoney,
                    state: {
                      status: ["FIRM_BANK_FAILED"],
                      orderBy: "DESC",
                      page: 0,
                      size: 10,
                      tab: "1",
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.withdrawal?.firmBankFailed)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.settlementOutmoney,
                    state: {
                      status: ["FAILED"],
                      orderBy: "DESC",
                      page: 0,
                      size: 10,
                      tab: "1",
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.withdrawal?.failed)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.appraisalStatus,
                    state: {
                      status: "CONFIRM_WAIT",
                      sortType: "DESC",
                      page: 0,
                      size: 10,
                      list: [],
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.appraisal?.confirmWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.appraisalStatus,
                    state: {
                      status: "RETURN_WAIT",
                      sortType: "DESC",
                      page: 0,
                      size: 10,
                      list: [],
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.appraisal?.returnWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.physicalOrder,
                    state: {
                      path: "search",
                      status: ["APPROVAL"],
                      sortType: "DESC",
                      page: 0,
                      size: 10,
                      total: 0,
                      list: [],
                      shopRangeList: [],
                      shipmentStatus: [],
                      shopSearch: "",
                      dateType: "CREATED",
                      selected: [],
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.goods?.approval)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directStatus,
                    state: {
                      page: 0,
                      size: 10,
                      dateType: "CREATED",
                      tab: "REGISTER",
                      numberType: "ORDER_ID",
                      userType: "ALL",
                      shopType: "ALL",
                      numberKeyword: "",
                      categoryId: "",
                      name: "",
                      inventoryLocation: "",
                      userKeyword: "",
                      shopKeyword: "",
                      statusLevel: "",
                      status: "INBOUND_COMPLETE",
                      registerList: [],
                      saleList: [],
                      releaseList: [],
                    },
                  });
                }}
              >
                {numberWithCommas(
                  processingStatus?.safeTradeItem?.inboundComplete
                )}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directStatus,
                    state: {
                      page: 0,
                      size: 10,
                      dateType: "CREATED",
                      tab: "REGISTER",
                      numberType: "ORDER_ID",
                      userType: "ALL",
                      shopType: "ALL",
                      numberKeyword: "",
                      categoryId: "",
                      name: "",
                      inventoryLocation: "",
                      userKeyword: "",
                      shopKeyword: "",
                      statusLevel: "",
                      status: "VERIFY_WAIT",
                      registerList: [],
                      saleList: [],
                      releaseList: [],
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.safeTradeItem?.verifyWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directStatus,
                    state: {
                      page: 0,
                      size: 10,
                      dateType: "CREATED",
                      tab: "REGISTER",
                      numberType: "ORDER_ID",
                      userType: "ALL",
                      shopType: "ALL",
                      numberKeyword: "",
                      categoryId: "",
                      name: "",
                      inventoryLocation: "",
                      userKeyword: "",
                      shopKeyword: "",
                      statusLevel: "",
                      status: "SELL_CONFIRM_WAIT",
                      registerList: [],
                      saleList: [],
                      releaseList: [],
                    },
                  });
                }}
              >
                {numberWithCommas(
                  processingStatus?.safeTradeItem?.sellConfirmWait
                )}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directStatus,
                    state: {
                      page: 0,
                      size: 10,
                      dateType: "CREATED",
                      tab: "REGISTER",
                      numberType: "ORDER_ID",
                      userType: "ALL",
                      shopType: "ALL",
                      numberKeyword: "",
                      categoryId: "",
                      name: "",
                      inventoryLocation: "",
                      userKeyword: "",
                      shopKeyword: "",
                      statusLevel: "",
                      status: "PAYMENT_DONE",
                      registerList: [],
                      saleList: [],
                      releaseList: [],
                    },
                  });
                }}
              >
                {numberWithCommas(processingStatus?.safeTradeItem?.paymentDone)}
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div className={classes.wrap}>
        <div className={classes.label}>문의현황</div>
        <table cellPadding={0} cellSpacing={0} className={classes.table}>
          <tr>
            <th rowSpan={3}>고객문의</th>
            <th colSpan={1}>대기</th>
            <th colSpan={3}>금일</th>
            <th rowSpan={3}>상품문의</th>
            <th colSpan={1}>대기</th>
            <th colSpan={3}>금일</th>
          </tr>
          <tr>
            <th>답변대기</th>
            <th>문의</th>
            <th>답변완료</th>
            <th>합계</th>
            <th>답변대기</th>
            <th>문의</th>
            <th>답변완료</th>
            <th>합계</th>
          </tr>
          <tr>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.serviceInquiry,
                    state: {
                      page: 0,
                      size: 10,
                      qnaStatus: ["PENDING"],
                    },
                  });
                }}
              >
                {numberWithCommas(inquiryStatus?.customerInquiry?.answerWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.serviceInquiry,
                    state: {
                      page: 0,
                      size: 10,
                      qnaStatus: ["PENDING", "INQUIRED"],
                      startDate: formatTime(),
                      endDate: formatTime(),
                      qnAType: "INQUIRED",
                    },
                  });
                }}
              >
                {numberWithCommas(inquiryStatus?.customerInquiry?.todayInquiry)}
              </span>
            </td>
            <td>
              {numberWithCommas(inquiryStatus?.customerInquiry?.todayAnswered)}
            </td>
            <td>
              {numberWithCommas(
                inquiryStatus?.customerInquiry?.todayInquiry +
                  inquiryStatus?.customerInquiry?.todayAnswered
              )}
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directInquiry,
                    state: {
                      page: 0,
                      size: 10,
                      isAnswered: "false",
                    },
                  });
                }}
              >
                {numberWithCommas(inquiryStatus?.itemInquiry?.answerWait)}
              </span>
            </td>
            <td>
              <span
                className={classes.link}
                onClick={() => {
                  history.push({
                    pathname: routes.directInquiry,
                    state: {
                      page: 0,
                      size: 10,
                      isAnswered: "false",
                      startDate: formatTime(),
                      endDate: formatTime(),
                      dateType: "CREATED",
                    },
                  });
                }}
              >
                {numberWithCommas(inquiryStatus?.itemInquiry?.todayInquiry)}
              </span>
            </td>
            <td>
              {numberWithCommas(inquiryStatus?.itemInquiry?.todayAnswered)}
            </td>
            <td>
              {numberWithCommas(
                inquiryStatus?.itemInquiry?.todayInquiry +
                  inquiryStatus?.itemInquiry?.todayAnswered
              )}
            </td>
          </tr>
        </table>
      </div>
      <div className={classes.wrap} style={{ marginBottom: 20 }}>
        <Panel>
          <PanelBar onSelect={handleSelect} expanded={expanded}>
            <PanelBarItem title={""}>
              {loading === false ? (
                <TileLayout
                  columns={4}
                  positions={
                    Object.keys(positionData).length === 0
                      ? initData
                      : positionData
                  } //위치 관련 정보
                  items={accordionContent}
                  onReposition={handleReposition}
                  rowHeight={255}
                  gap={{
                    rows: 10,
                    columns: 10,
                  }}
                  style={{
                    backgroundColor: "white",
                    gridAutoRows: "auto",
                  }}
                />
              ) : (
                <div style={{ textAlign: "center", paddingBottom: 15 }}>
                  <h4>LOADING</h4>
                  <span className="k-icon k-i-loading" />
                </div>
              )}
            </PanelBarItem>
          </PanelBar>
        </Panel>
      </div>
    </>
  );
};

export default StatusDashBoard;
